import { useId } from 'react'
import CtaLink, { type CtaLinkProps } from './cta-link'
import HubspotCTA, { type HubspotCTAProps } from './hubspot-cta'
import type { Entry } from 'contentful'
import type { TypeComponentCtaLinkSkeleton, TypeComponentHubspotCtaSkeleton } from '@/types/ctf'

type CtaLink = {
  type?: 'ctaLink'
} & CtaLinkProps

type CtaLinkEntry = Entry<TypeComponentCtaLinkSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS'>
type CtaLinkWithType = Partial<Omit<CtaLinkEntry['fields'], 'link' | 'style'>> & {
  type: 'componentCtaLink'
  link?: Partial<CtaLinkEntry['fields']['link']> | null
} & CtaLinkProps

type HubspotCtaEntry = Entry<TypeComponentHubspotCtaSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS'>
type HubspotCtaWithType = Omit<HubspotCtaEntry['fields'], 'style'> & {
  type: 'componentHubspotCta'
} & HubspotCTAProps

export type DynamicActionProps = (CtaLink | CtaLinkWithType | HubspotCtaWithType) & { id?: string; label?: string }

/** Renders the correct CTA component based on the type */
export default function DynamicAction(fields: DynamicActionProps) {
  const props = fields.fields ?? fields

  const uid = useId()
  // props.id = uid + props.id
  switch (props.type) {
    case 'componentHubspotCta':
      const { type: hubspotTypename, ...hubspotCtaProps } = props
      return <HubspotCTA {...hubspotCtaProps}>{props.label}</HubspotCTA>
    case 'componentCtaLink':
      const { link, type: contentfulCtaLinkTypename, ...contentfulCtaLinkProps } = props

      return (
        props?.link?.fields?.url && (
          <CtaLink {...contentfulCtaLinkProps} to={link?.fields?.url}>
            {props.label}
          </CtaLink>
        )
      )
    case 'ctaLink':
    default:
      const { type: ctaLinkTypename, ...ctaLinkProps } = props

      return (
        <CtaLink className={fields.className} to={ctaLinkProps.link.fields.url} {...ctaLinkProps}>
          {props.label || props.children}
        </CtaLink>
      )
  }
}
