import { useState } from 'react'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'
import { useClickAnyWhere, useEventListener } from 'usehooks-ts'
import DynamicAction from '@/components/ui/dynamic-action'
import { AnimatePresence, motion } from 'framer-motion'
import { cx } from '@/utils/strings.ts'
import colors from '@/constants/colors'

export default function HeaderDropdownMenu({ title, links, backgroundColor = colors.primaryBlue }: any) {
  // Hide the outline only when the user clicks on the button
  const [hideOutline, setHideOutline] = useState(false)
  useClickAnyWhere(() => setHideOutline(false))
  useEventListener('keydown', () => {
    setHideOutline(false)
  })

  return (
    <Popover className="relative" as="nav">
      {({ open }) => (
        <>
          <PopoverButton
            type="button"
            className={cx(
              'flex items-center gap-1 py-3 px-1 text-base xl:text-lg hover:text-gray-300 dark:hover:text-tealBlue',
              hideOutline && `outline-none`,
              backgroundColor === colors.primaryBlue && 'hover:text-gray-300',
              backgroundColor === colors.white && 'hover:text-indigoBlue',
              'dark:hover:text-tealBlue'
            )}
            onClick={() => setHideOutline(true)}
          >
            <span>{title}</span>
            <span className={cx('font-bwi text-xs duration-150 rotate-0', open && `-rotate-90`)}>&#xe92d;</span>
          </PopoverButton>

          <AnimatePresence>
            {open && (
              <PopoverPanel
                className="absolute z-10 py-4 bg-white rounded-2xl overflow-hidden min-w-[21rem] drop-shadow-xl"
                static
                as={motion.div}
                initial={{
                  transitionDuration: '50ms',
                  transitionProperty: 'opacity transform',
                  transitionTimingFunction: 'ease-out',
                  opacity: 0.9,
                  scale: 0.95,
                }}
                animate={{
                  opacity: 1,
                  scale: 1,
                }}
                exit={{}}
              >
                <div className="grid m-2">
                  {links.map((link) => (
                    <>
                      <DynamicAction
                        key={link.sys.id}
                        className="arrow-link no-underline flex items-center gap-1 !py-2 !px-6 duration-200 !text-primaryBlue hover:bg-gray-100 hover:!text-indigoBlue rounded-md"
                        {...(link.fields as any)}
                      />
                    </>
                  ))}
                </div>
              </PopoverPanel>
            )}
          </AnimatePresence>
        </>
      )}
    </Popover>
  )
}
