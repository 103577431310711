import { useMemo } from 'react'

import CTALink, { type CtaLinkProps } from '@/components/ui/cta-link'

export type HubspotCTAProps = Omit<CtaLinkProps, 'to'> & {
  embedCode: string
}

function HubspotCTA({ embedCode, children, ...props }: HubspotCTAProps): JSX.Element {
  const { hubspotId, href } = useMemo(
    () => ({
      hubspotId: embedCode?.match(/data-hubspot-wrapper-cta-id="(\d+)"/)?.[1] || '',
      href: embedCode?.match(/href="([^"]+)"/)?.[1] || '',
    }),
    [embedCode]
  )

  return (
    <>
      <CTALink to={href} {...props}>
        {children}
      </CTALink>
      <div
        className={`hidden hs-cta-embed hs-cta-simple-placeholder hs-cta-embed-${hubspotId}`}
        data-hubspot-wrapper-cta-id={hubspotId}
      />
      <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/22371289.js" />
    </>
  )
}

export default HubspotCTA
