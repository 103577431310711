export type AnyLinkType = Queries.CtaLinkFragment | Queries.HeaderDropdownMenuFragment | Queries.HeaderRichMenuFragment

export const flattenLinkGroups = (links: Queries.HeaderFragment['mainNavItems']) =>
  links?.reduce((acc, link) => {
    if (link.sys.contentType.sys.id === 'headerLinkGroup') {
      return [...acc, ...link.fields.links]
    }
    return [...acc, link]
  }, [] as AnyLinkType[]) || []

const useFindHeaderWithLocale = (query) => {
  return query.nodes.find((header) => header.node_locale === 'en-us') // todo: replace 'en-us' with currentLocale
}

const useStaticHeaders = () => {
  const query = `
    query StaticHeaders {
      defaultHeaderQuery: allContentfulHeader(filter: { isDefault: { eq: true } }) {
        nodes {
          ...Header
        }
      }
      whiteGoHeaderQuery: allContentfulHeader(filter: { contentful_id: { eq: "7ykOpxmhJ4f3qJ3qwqGGK1" } }) {
        nodes {
          ...Header
        }
      }
      blueGoHeaderQuery: allContentfulHeader(filter: { contentful_id: { eq: "2PWlO2FdpjxHZstu5gQwt7" } }) {
        nodes {
          ...Header
        }
      }
    }
  `
  return query
}

export const useDefaultHeader = () => {
  const query = useStaticHeaders()
  return useFindHeaderWithLocale(query.defaultHeaderQuery)
}

export const useWhiteGoHeader = () => {
  const query = useStaticHeaders()
  return useFindHeaderWithLocale(query.whiteGoHeaderQuery)
}

export const useBlueGoHeader = () => {
  const query = useStaticHeaders()
  return useFindHeaderWithLocale(query.blueGoHeaderQuery)
}
