import DynamicAction from '@/components/ui/dynamic-action'
import ImageContentful from './ui/image-contentful'

type HeaderCardProps = {
  className?: string
  onLinkClick?: () => void
}

export default function HeaderCard({ className, headline, subtext, links, image, onLinkClick }: HeaderCardProps) {
  return (
    <div className={className}>
      <h5 className="text-2xl font-[500] text-indigoBlue flex mb-1">
        {image && (
          <div className="hidden md:block w-8 h-8 mr-[0.875rem]">
            <ImageContentful {...image.fields} className="[&_svg]:w-full [&_svg]:h-full [&_path]:fill-indigoBlue" />
          </div>
        )}
        <span>{headline}</span>
      </h5>
      {subtext && <p className="text-base leading-6 mb-[1.125rem] text-[#545E66]">{subtext}</p>}
      {links?.length > 0 && (
        <div className="grid md:grid-rows-subgrid md:grid-flow-col lg:flex lg:flex-row lg:flex-nowrap lg:content-stretch gap-5 md:gap-9">
          {links.map((link) => {
            return (
              <div
                key={link.sys.id}
                className="relative md:flex-[0 1 280px] bg-white rounded-2xl px-[1.125rem] pt-3 pb-4 flex-1 transition-shadow hover:shadow-lg duration-300"
              >
                <DynamicAction
                  className="stretched-link no-underline !text-primaryBlue hover:!text-indigoBlue transition-colors !text-base leading-[120%] self-start !py-0"
                  onClick={onLinkClick}
                  {...(link.fields.link as any)}
                />
                {link.fields.description && (
                  <p className="text-sm text-[#545E66] md:hidden mb-0">{link.fields.description}</p>
                )}
                {link.fields.shortDescription && (
                  <p className="text-sm text-[#545E66] hidden md:block mb-0">{link.fields.shortDescription}</p>
                )}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}
