import { useEffect, useRef, useState } from 'react'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'
import { useClickAnyWhere, useEventListener } from 'usehooks-ts'

import { AnimatePresence, motion } from 'framer-motion'
import DynamicAction from '@/components/ui/dynamic-action'
import HeaderCard from '@/components/header-card'
import { buttonStyles } from '@/components/ui/cta-link'
import colors from '@/constants/colors'
import { cx } from '@/utils/strings.ts'

// type HeaderRichMenuProps = Queries.HeaderRichMenuFragment & {
type HeaderRichMenuProps = {
  className?: string
  onLinkClick?: () => void
  backgroundColor?: string
}

type HeaderRichMenuContentProps = {
  // cards: HeaderRichMenuProps['cards']
  // links: HeaderRichMenuProps['links']
  isCondensed: boolean
  onLinkClick?: () => void
}

export function HeaderRichMenuContent({ cards, links, isCondensed, onLinkClick }: HeaderRichMenuContentProps) {
  const cardGroups = cards?.reduce((acc, card) => {
    if (card.fields.links?.length > 1) {
      acc.push({ cards: [card], count: card.fields.links.length })
    } else {
      const lastGroup = acc[acc.length - 1]
      if (lastGroup && lastGroup.count < 2) {
        lastGroup.cards.push(card)
        lastGroup.count += 1
      } else {
        acc.push({ cards: [card], count: 1 })
      }
    }
    return acc
  }, [])

  return (
    <>
      {cardGroups?.length > 0 && (
        <div
          className={cx(
            'grid max-w-full gap-y-10 md:gap-y-[45px] md:px-[45px]',
            !isCondensed ? `grid-flow-col` : `grid-cols-2 gap-x-9`
          )}
        >
          {cardGroups.map((group) =>
            group.cards.map((card) => (
              <HeaderCard
                key={card.sys.id}
                className={cx(
                  `grid gap-0`,
                  !isCondensed &&
                    `md:border-r-[1px] md:border-gray-300 md:px-10 md:first-of-type:pl-0 md:last-of-type:pr-0 md:last-of-type:border-r-0`,
                  group.cards.length > 1
                    ? `col-span-2 [&>*]:col-span-2 md:col-span-1 md:[&>*]:!col-auto`
                    : `col-span-2`,
                  !isCondensed ? `grid-rows-subgrid row-span-3` : `grid-cols-subgrid`,
                  group.cards.length === 1 && isCondensed && `[&>*]:col-span-2`
                )}
                onLinkClick={onLinkClick}
                {...card.fields}
              />
            ))
          )}
        </div>
      )}
      {links?.length > 0 && (
        <div
          className={cx(
            `w-full mt-7 flex flex-col gap-4`,
            `md:flex-row md:items-center md:gap-x-6 md:gap-y-4 md:px-12`,
            `lg:py-4 lg:px-10 lg:bg-white lg:flex-row lg:flex-wrap`,
            isCondensed && links.length > 3 && `md:grid md:grid-cols-2`
          )}
        >
          {links.map((link) => (
            <DynamicAction
              key={link.sys.id}
              className={cx(
                `no-underline transition-colors !text-base`,
                (link.fields.style === buttonStyles.btnPlain || link.fields.style === buttonStyles.btnArrow) &&
                  `!text-primaryBlue hover:!text-indigoBlue !py-0 !h-auto`,
                link.fields.style === buttonStyles.btnSolid && `!bg-primaryBlue`
              )}
              onClick={onLinkClick}
              {...(link.fields as any)}
              backgroundColor={colors.white}
            />
          ))}
        </div>
      )}
    </>
  )
}

export default function HeaderRichMenu({
  className,
  title,
  cards,
  links,
  onLinkClick,
  backgroundColor = colors.primaryBlue,
}: HeaderRichMenuProps) {
  const [responsive, setResponsive] = useState({ screenWidth: 0, bottom: 0, left: 0 })
  const buttonRef = useRef<HTMLButtonElement>()

  const onResize = () => {
    if (buttonRef.current) {
      const box = buttonRef.current.getBoundingClientRect()
      setResponsive({ screenWidth: window.innerWidth, bottom: box.bottom, left: box.left })
    }
  }
  useEventListener('resize', onResize)
  useEffect(() => {
    onResize()
  }, [])

  // Hide the outline only when the user clicks on the button
  const [hideOutline, setHideOutline] = useState(false)
  useClickAnyWhere(() => setHideOutline(false))
  useEventListener('keydown', () => {
    setHideOutline(false)
  })

  const isCondensed = responsive.screenWidth < 1314

  return (
    <Popover as="nav" className={className}>
      {({ open }) => (
        <>
          <PopoverButton
            ref={buttonRef}
            className={cx(
              'flex items-center gap-1 py-3 pr-1 hover:text-gray-300 dark:hover:text-tealBlue',
              hideOutline && `outline-none`,
              backgroundColor === colors.primaryBlue && 'hover:text-gray-300',
              backgroundColor === colors.white && 'hover:text-indigoBlue',
              'dark:hover:text-tealBlue'
            )}
            onClick={() => setHideOutline(true)}
          >
            <span className="text-base xl:text-lg">{title}</span>
            <span className={cx('font-bwi text-xs duration-150 rotate-0', open && `-rotate-90`)}>&#xe92d;</span>
          </PopoverButton>

          <div
            className={cx('absolute left-5 translate-y-1', !isCondensed ? `w-[calc(100% - 2.5rem)]` : `w-[680px]`)}
            style={{
              left: !isCondensed ? '1.25rem' : responsive.left,
            }}
          >
            <AnimatePresence>
              {open && (
                <PopoverPanel
                  className="bg-gray-100 rounded-2xl transform-gpu overflow-hidden drop-shadow-xl max-w-[1440px] pt-[45px] w-full"
                  key={`header-${title}`}
                  static
                  as={motion.div}
                  initial={{
                    transitionDuration: '20ms',
                    transitionProperty: 'opacity transform',
                    transitionTimingFunction: 'ease-out',
                    opacity: 0.9,
                    scale: 0.95,
                  }}
                  animate={{
                    opacity: 1,
                    scale: 1,
                  }}
                  exit={{}}
                >
                  <HeaderRichMenuContent
                    cards={cards}
                    links={links}
                    isCondensed={isCondensed}
                    onLinkClick={onLinkClick}
                  />
                </PopoverPanel>
              )}
            </AnimatePresence>
          </div>
        </>
      )}
    </Popover>
  )
}
