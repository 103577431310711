import Meta from '@/components/meta'
import Header from '@/components/header.tsx'
import Footer from '@/components/footer.tsx'
import { useEffect } from 'react'

type PageWrapperProps = {
  children: any
  seo: any
  headerNav?: any
  headerBanners?: any
  footerNav?: any
  bodyTw?: string
  breadcrumb?: any[]
  darkMode?: boolean
}

export default function PageWrapper({
  children,
  seo,
  headerNav = null,
  headerBanners = null,
  footerNav = null,
  bodyTw = 'bg-transparent',
  breadcrumb = [],
  darkMode = false,
}: PageWrapperProps) {
  useEffect(() => {
    const html = document.documentElement
    if (darkMode) {
      html.classList.add('dark')
    } else {
      html.classList.remove('dark')
    }
  }, [darkMode])

  return (
    <>
      <Header {...headerNav?.fields} id={headerNav?.sys?.id} headerBanners={headerBanners} />
      <Meta seo={seo} breadcrumb={breadcrumb} />
      <main className={bodyTw}>{children}</main>
      <Footer footerNav={footerNav} showLinkSections={headerNav?.fields?.mainNavItems?.length > 0} />
    </>
  )
}
